.wrapper { margin: 0;}

.column-body {background: #FFF; padding: 20px;}
.column-grey {background: @well-bg; }

.column-right { padding: 20px 3%;}
.column-right p {line-height: 2;}
.column-right h1,
.column-right h2,
.column-right h3,
.column-right h4 {margin-bottom: 15px;}

.main-container { padding-top: 20px; padding-bottom: 40px; }

.breadcrumb {list-style: none; margin:0; padding: 0 0 10px 0; border-bottom: 1px solid #EEE; margin-bottom: 10px;}
.breadcrumb li {color: #DDD; padding: 0; display: inline-block;}

.breadcrumb li:after { font-family: 'Glyphicons Regular'; content: "\E224"; padding: 5px 5px 0 5px; display: inline-block; font-size:0.8em;}
.breadcrumb li a {color: #BBB;}

.breadcrumb li:last-child:after {content: '';}

.page-title {padding-bottom: 20px;}

.hub-listing a .panel { color: #999;}
.hub-listing a:hover .panel {color: #666; border: 1px solid #666;}
.hub-listing a {text-decoration: none;}

.hub-listing .panel .panel-heading,
.hub-listing .panel .panel-body {text-align:center; background: none;}

.hub-listing .panel .panel-heading {border-bottom: 0; font-size: 1.4em; font-weight: bold;}

.hub-listing .panel .panel-body .glyphicons {font-size: 3em; margin: 0 0 20px 0; color: #F7941E;}

.customer-profile-dl {margin-bottom: 0;}
.customer-profile-dl dt {text-align: left; padding-bottom: 5px; width: 120px;}
.customer-profile-dl dd {margin-left: 120px;}

.anchor-target {
   display:block;
   height:63px; /* this is the height of your header */
   margin-top:-63px; /* this is again negative value of the height of your header */
   visibility:hidden;
}

@media (min-width: @screen-sm-min) {

	.column-body .inner {width: 750px; margin: 0 auto;}
	.column-right .inner {width: 750px; margin: 0 auto;}

}

// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width: @screen-md-min) {
    .column-body { width: 70%; float:left;}
    .column-right { width: 30%; float: right; padding: 40px 20px 20px 20px;}
    .column-right .inner {width: 90%; max-width: 240px;}
    .column-body .inner {width:90%; max-width: 650px; float:right; margin-right: 4%;}
}

// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: @screen-lg-min) {

	.column-right .inner {width: 90%; max-width: 300px;}
    .column-body .inner {width:90%; max-width: 820px; }

}
