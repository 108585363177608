//
// Utility classes
// --------------------------------------------------

// Floats
// -------------------------

.clearfix {
    .clearfix();
}

.center-block {
    .center-block();
}

.pull-right {
    float: right !important;
}

.pull-left {
    float: left !important;
}

// Toggling content
// -------------------------

// Note: Deprecated .hide in favor of .hidden or .sr-only (as appropriate) in v3.0.1
.hide {
    display: none !important;
}

.show {
    display: block !important;
}

.invisible {
    visibility: hidden;
}

.text-hide {
    .text-hide();
}

// Hide from screenreaders and browsers
//
// Credit: HTML5 Boilerplate

.hidden {
    display: none !important;
}

// For Affix plugin
// -------------------------

.affix {
    position: fixed;
}

/**
 * Margins
 */
.margin-top-0 {
    margin-top: 0;
}

.margin-bottom-0 {
    margin-bottom: 0;
}

.margin-top-5 {
    margin-top: 5px;
}

.margin-bottom-5 {
    margin-bottom: 5px;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-bottom-30 {
    margin-bottom: 30px;
}