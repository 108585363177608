// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

.button-variant-solid(@color, @color-alt) {
    color:              @color-alt;
    background-color:   @color;
    border-color:       @color;
    &:hover,
    &:focus,
    &.focus {
        color:            @color-alt;
        background-color: darken(@color, 10%);
        border-color:     darken(@color, 10%);
    }
}

.button-variant(@color, @color-alt) {

    color:            @color;
    background-color: transparent;
    border-color:     @color;

    &:hover,
    &:focus,
    &.focus {
        color:            @color-alt;
        background-color: @color;
    }

    &:active,
    &.active,
    .open > .dropdown-toggle& {
        color:            @color-alt;
        background-color: @color;

        &:hover,
        &:focus,
        &.focus {
            color:            @color-alt;
            background-color: @color;
        }
    }
    &:active,
    &.active,
    .open > .dropdown-toggle& {
        background-image: none;
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &:hover,
        &:focus,
        &.focus {
            background-color: @color;
            color:            darken(@color-alt, 10%);
        }
    }

    .badge {
        color:            @color-alt;
        background-color: @color;
    }
}

// Button sizes
.button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
    padding:       @padding-vertical @padding-horizontal;
    font-size:     @font-size;
    line-height:   @line-height;
    border-radius: @border-radius;
    &.btn-wide {
        padding-left: @padding-horizontal * 2;
        padding-right: @padding-horizontal * 2;
    }
}
