.merchant-brand-preview {
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}

.merchant-brand-preview:hover {
  cursor: pointer;
}

.cover-contain { float: right; width: 50%; height: 100px;}

.logo-contain {width: 100px; height: 100px; position: relative; margin: 20px;z-index: 100; float:left;}
.logo-rollover {position: absolute; top:0; width: 100px; height: 100px; background: #FFF; display: none;z-index: 100; text-align: center; opacity: 0.8;}
.logo-rollover .glyphicons {color: #444; font-size: 25px; margin: 25px 0 5px 0; text-align: center; }
.logo-rollover p {margin: 0; color: #444; font-weight:bold;}
.logo-contain:hover .logo-rollover { display: block; }

.cover-rollover {position: absolute; top:0; width: 100%; height: 300px; background: #FFF; display: none;z-index: 100; text-align: center; opacity: 0.8; margin-left: -50%; padding-top: 20px;}
.cover-rollover .glyphicons {font-size: 25px; margin: 20px 0 5px 0; color: #444;}
.cover-rollover p {margin: 0; color: #444; font-weight:bold;}
.cover-contain:hover .cover-rollover{ display: block; }

.colour-swatch label {text-align:center; padding: 40px 10px 10px 10px; width: 100%; border: 3px solid #FFF;}
.colour-swatch label:hover {cursor:pointer; border: 3px solid #DDD;}
.colour-swatch label input {text-align:center; margin: 0 auto;}

.uploading-screen {position: fixed; background: rgba(0,0,0,0.8); width: 100%; height:100%;  z-index:9999;}
.uploading-screen .inner { width: 50%; margin: 200px auto 0 auto; color: #FFF; text-align: center;}
.uploading-screen .inner .title { font-size: 4em;}

@media (min-width: @screen-sm-min) {
  .cover-contain {width: 70%;}
  .cover-rollover {margin-left: -30%;}
}


@media (min-width: @screen-md-min) {

}

@media (min-width: @screen-lg-min) {
  .cover-contain {width: 80%;}
  .cover-rollover {margin-left: -20%;}
}
