.dashboard {
    .dashboard-header {
        border-bottom: 1px solid #eee;
        padding: 0 0 10px 0;
        margin: 0 0 15px 0;
        .dashboard-header-title {
            > h1, > h2, > h3, > h4, > h5, > h6, > .h1, > .h2, > .h3, > .h4, > .h5, > .h6 {
                margin: 0;
            }
        }
        .dashboard-header-jumps {
            clear: both;
            margin: 4px 0 0 0;
            text-align: left;
            .text-muted;
            font-size: 14px;
        }
        .dashboard-header-filters {
            > .btn-group {
                float: none;
                margin: 10px 0 0 0;
            }
            .dashboard-header-filters-filter {
                > button {
                    > .glyphicons {
                        top: 3px;
                        margin-right: 3px;
                    }
                    > .caret {
                        margin-left: 3px;
                    }
                }
                > ul.dropdown-menu {
                    > li {
                        .glyphicons-check {
                            display: none;
                        }
                        .glyphicons-unchecked {
                            display: inline-block;
                        }
                        &.active {
                            .glyphicons-check {
                                display: inline-block;
                            }
                            .glyphicons-unchecked {
                                display: none;
                            }
                            > a {
                                background: @dropdown-bg;
                                color:      @dropdown-link-color;
                            }
                            > a:hover, > a:focus {
                                color:            @dropdown-link-hover-color;
                                background-color: @dropdown-link-hover-bg;
                            }
                        }
                    }
                }
            }
        }
    }
    .dashboard-content-loader {
        display: none;
        padding: 50px 0 0 0;
        text-align: center;
        > img {
            display: block;
            margin: 0 auto 10px auto;
        }
        > p {
            margin: 0;
            .text-muted;
        }
    }
}
.dashboard-region {
    margin: @line-height-computed 0 (@line-height-computed*2) 0;
    .dashboard-region-body {
        h2 {
            margin: 0 0 (@line-height-computed/2) 0;
            font-size: 24px;
            color: darken(@text-muted, 10%);
            > small {
                font-size: 20px;
                .text-muted;
            }
        }
        .dashboard-chart_table {
            max-width: 450px;
            margin: 0 auto;
            td {
                .dashboard-chart_table-more-toggle {
                    font-size: 12px;
                }
            }
        }
    }
}

.dashboard--customers {
    .dashboard--customers-ages {
        width: 320px;
        margin: @line-height-computed auto 0 auto;
        .dashboard--customers-ages-age {
            clear: both;
            float: left;
            width: 100%;
            margin: 10px 0 0 0;
            &:first-child {
                margin-top: 0;
            }
            > p {
                float: left;
                clear: both;
                color: #666;
                font-size: 14px;
                text-align: right;
                width: 75px;
                line-height: 1;
                margin: 2px 10px 0 0;
            }
            .dashboard--customers-ages-age-scale {
                float: left;
                border: solid 1px #e0e0e0;
                margin: 0;
                width: 205px;
                height: 18px;
                background: #fff;
                .dashboard--customers-ages-age-scale-male, .dashboard--customers-ages-age-scale-female, .dashboard--customers-ages-age-scale-unknown {
                    height: 18px;
                    width: 0;
                    float: left;
                }
                .dashboard--customers-ages-age-scale-male {
                    background: @summary-color-male;
                }
                .dashboard--customers-ages-age-scale-female {
                    background: @summary-color-female;
                }
                .dashboard--customers-ages-age-scale-unknown {
                    background: @summary-color-unknown;
                }
            }
        }
    }
    .dashboard--customers-locations {
        padding-top: (@line-height-computed*2);
        .dashboard--customers-locations-key {
            text-align: center;
            margin-bottom: (@line-height-computed/2);
            > p {
                display: inline-block;
                margin: 0 20px 0 0;
                font-size: 12px;
                color: #666;
                line-height: 1;
                > .dashboard--customers-locations-key-color {
                    display: inline-block;
                    height: 12px;
                    width: 12px;
                    background: red;
                }
            }
        }
        .dashboard--customers-locations-chart {

        }
        .dashboard--customers-locations-map {
            width: 100%;
            height: 300px;
            background: #eee;
        }
    }
}

.dashboard--tags.dashboard-region {
    .dashboard-region-body {
        .dashboard-chart_table {
            margin-top: @line-height-computed;
        }
    }
}

@media (min-width: @screen-sm-min) {
    .dashboard {
        .dashboard-header {
            .dashboard-header-filters {
                > .btn-group {
                    float:  right;
                    margin: 0;
                }
            }
        }
    }
    .dashboard--customers {
        .dashboard--customers-ages {
            width: 100%;
            max-width: 450px;
            .dashboard--customers-ages-age {
                .dashboard--customers-ages-age-scale {
                    width: 365px;
                }
            }
        }
    }
}
@media (min-width: @screen-md-min) {
    .dashboard--customers {
        .dashboard--customers-ages {
            max-width: 100%;
            margin: 25px 0 0 0;
            .dashboard--customers-ages-age {
                .dashboard--customers-ages-age-scale {
                    width: 208px;
                }
            }
        }
    }
}
@media (min-width: @screen-lg-min) {
    .dashboard--customers {
        .dashboard--customers-ages {
            .dashboard--customers-ages-age {
                .dashboard--customers-ages-age-scale {
                    width: 275px;
                }
            }
        }
    }
}

